/* Imagem de fundo cobrindo toda a tela */
.background {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/my-farm-land-556ff.appspot.com/o/s-l1200.jpg?alt=media&token=b6351a89-cb1a-4cb1-9b9f-edd0c0c072d8'); /* Substitua pela URL da sua imagem */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Container centralizado */
.centered-container {
  width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
}
